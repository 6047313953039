import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: 'profissional',
    loadChildren: './profissional/profissional.module#ProfissionalModule'
  },
  {
    path: 'loja',
    loadChildren: './loja/loja.module#LojaModule'
  },
  {
    path: 'sistema',
    loadChildren: './sistema/sistema.module#SistemaModule'
  },
  {
    path: 'cliente',
    loadChildren: './cliente/cliente.module#ClienteModule'
  },
  {
    path: 'jurado',
    loadChildren: './jurado/jurado.module#JuradoModule'
  },
  {
    path: '',
    loadChildren: './site/site.module#SiteModule'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
